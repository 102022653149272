<template>
  <v-flex xs12 sm8 md4>
    <v-card class="elevation-12">
      <v-toolbar>
        <v-toolbar-title>Авторизация</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form v-model="valid" lazy-validation>
          <v-text-field
            id="passwod"
            label="Пароль"
            prepend-icon="mdi-lock"
            v-model="password"
            type="password"
            :rules="passwordRules"
            required
          ></v-text-field>
          <v-text-field
            id="verify"
            label="Повтор пароля"
            prepend-icon="mdi-lock"
            v-model="verify"
            type="password"
            :rules="[passwordMatch, passwordRules]"
            required
          ></v-text-field>
          <v-checkbox
            v-model="checkbox"
            :rules="[
              (v) => !!v || 'Согласен с уловиями политики конфиденциальности',
            ]"
          >
            <template v-slot:label>
              <div>
                Согласен с
                <a
                  target="_blank"
                  href="https://a-prokat.com/confidential"
                  @click.stop
                >
                  политикой конфиденциальности
                </a>
              </div>
            </template>
          </v-checkbox>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="setPass" :loading="loading" :disabled="!passwordMatchBtn"
          >Задать пароль</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-alert v-if="alert.show" :type="alert.type" dense lazy>{{
      alert.text
    }}</v-alert>
  </v-flex>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      valid: true,
      phone: '',
      phoneRules: [
        (v) => !!v || 'Обязательное для заполнения',
        (v) =>
          (v && v.replace(/\D+/g, '').length == 11) ||
          'Телефон должен содержать 11 цифр начиная с 7',
      ],
      password: '',
      verify: '',
      passwordRules: [
        (v) => !!v || 'Обязательное для заполнения',
        (v) =>
          (v && v.length >= 6) || 'Пароль должен содержать минимум 6 символов',
      ],
      checkbox: false,
      alert: {
        show: false,
        type: 'info',
        text: '',
      },
      restorebuttonActive: true,
      loading: false,
    };
  },
  methods: {
    ...mapActions(['setNewPassword']),
    async login() {
      this.$router.push('/');
    },
    async setPass() {
      this.loading = true;
      const resp = await this.setNewPassword(this.password);
      this.alert.show = true;
      this.alert.text = resp.data.message;
      this.alert.type = resp.data.status ? 'info' : 'error';
      this.loading = false;
      this.restorebuttonActive = false;
      setTimeout(() => {
        this.alert.show = false;
        this.restorebuttonActive = true;
        if (resp.data.status) this.login();
      }, 5000);
    },
  },
  computed: {
    // ...mapGetters(["user"]),
    passwordMatch() {
      return () => this.password === this.verify || 'Пароли должны совпадать';
    },
    passwordMatchBtn() {
      return (
        this.password &&
        this.password === this.verify &&
        this.checkbox &&
        this.restorebuttonActive
      );
    },
  },
};
</script>
<style lang="scss" scoped></style>
