<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <AuthForm v-if="!restore" />
      <RestoreForm v-if="restore" />
    </v-layout>
  </v-container>
</template>

<script>
import AuthForm from '../components/AuthForm';
import RestoreForm from '../components/RestoreForm';
export default {
  components: {
    AuthForm,
    RestoreForm,
  },
  data() {
    return {
      restore: false,
    };
  },
  created() {
    if ('token' in this.$route.query) {
      this.restore = true;
    }
  },
};
</script>
